import { render, staticRenderFns } from "./TherapistSignUp.vue?vue&type=template&id=a79beb48&scoped=true&"
import script from "./TherapistSignUp.vue?vue&type=script&lang=ts&"
export * from "./TherapistSignUp.vue?vue&type=script&lang=ts&"
import style0 from "./TherapistSignUp.vue?vue&type=style&index=0&id=a79beb48&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a79beb48",
  null
  
)

export default component.exports