
import { Component, Watch } from "vue-property-decorator";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
import Content from "@/components/common/Content.vue";
import Vue from "@/interfaces/vue";
import { auth } from "@/api/index";
import i18n from "@/i18n";
const pageKey = "signup-therapist";

@Component({
  components: {
    VueHcaptcha,
    Content,
  },
  metaInfo: {
    title: i18n.t(`page-title.${pageKey}`).toString(),
    meta: [
      {
        name: "description",
        content: i18n.t(`meta-description.${pageKey}`).toString(),
      },
    ],
  },
})
export default class TherapistSignUp extends Vue {
  step = 1;
  isClicked = false;

  firstName = "";
  lastName = "";
  phone = "";
  email = "";
  password = "";
  password2 = "";
  chosenSpecialization = "";
  pwz = "";
  accept = false;
  sitekey = process.env.VUE_APP_SITE_KEY;

  get stageOneValidation() {
    return {
      firstName: this.firstName.trim() !== "",
      lastName: this.lastName.trim() !== "",
      phone: this.phone.trim() !== "",
      password: this.password.trim() !== "",
      password2: this.password===this.password2,
      email: this.email.trim() !== "" && /\S+@\S+\.\S+/.test(this.email),
    };
  }

  get stageOneValid() {
    return Object.values(this.stageOneValidation).reduce((prev, current) => {
      if (prev && current) return true;
      else return false;
    }, true);
  }

  get stageTwoValid() {
    return this.chosenSpecialization !== "" && this.accept;
  }

  get specializations() {
    return this.$store.state.dict?.specialization_ids;
  }

  nextStage() {
    if (this.stageOneValid) {
      this.step = 2;
      this.isClicked = false;
    } else {
      this.isClicked = true;
    }
  }

  async signup(captchaToken: string) {
    try {
      if (this.stageTwoValid) {
        auth.therapistSignup({
          user: {
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.email,
            password: this.password,
            phone_number: this.phone,
          },
          specializations: [this.chosenSpecialization],
          captcha_token: captchaToken,
        });
        this.step = 3;
        this.isClicked = false;
      } else {
        this.isClicked = true;
      }
    } catch (err) {
      console.log(err);
    }
  }

  validate() {
    (this.$refs.hcaptcha as Vue & {
      execute: () => boolean;
    }).execute();
  }

  onCaptchaExpired() {
    (this.$refs.hcaptcha as Vue & {
      reset: () => boolean;
    }).reset();
  }

  @Watch("step")
  scrollUp() {
    window.scrollTo(window.scrollX, 0);
  }
}
